import React, { Component, Suspense } from 'react'
import {HashRouter, Link, Route, Routes} from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Mensagem = React.lazy(() => import('./views/pages/mensagem/Mensagem'))
const Home = React.lazy(() => import('./views/pages/home/Home'))
const Header = React.lazy(() => import("./components/newHeader"))
const Footer = React.lazy(() => import("./components/AppFooter"))
const Quemsomos = React.lazy(() => import('./views/pages/Quemsomos'))
const Rodape = React.lazy (() => import ("./components/rodape"));
const Produtos = React.lazy (() => import ("./views/pages/produtos"));

class App extends Component {
  render() {
    return (
        <Suspense fallback={loading}>
          <Header />
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/mensagem" name="Mensagem Page" element={<Mensagem />} />
            <Route exact path="/home" name="Home page" element={<Home />} />
            <Route exact path='/quemsomos' name="Quem somos" element={<Quemsomos />} />
            <Route exact path='/produtos' name="Produtos" element={<Produtos />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
          <Rodape />
          <Footer />
        </Suspense>
    )
  }
}

export default App
